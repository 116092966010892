.hcFacetReset {
float: right;
}

.hcClickable {
color: hsl(200deg, 90%, 30%);
cursor: pointer;
display: inline-block;
margin-bottom: 16px !important;
}

.hcClickableBlock {
color: hsl(200deg, 90%, 30%);
cursor: pointer;
}

.hcClickableSpan {
color: hsl(200deg, 90%, 30%);
cursor: pointer;
display: inline;
}

.appearsInClass{
display: block !important;
margin-bottom: 40px !important;
}

.hcFacetValues {
margin-right: 6px;
}

/*.detLabel {
font-weight: bold;
}*/

.detLabel {
color: #4d4d4d;
text-transform: uppercase;
font-size: 0.85rem;
font-weight: 700;
}

.uriLabel {
font-weight: bold;
color: #aaa;
font-size: 80%;
}

hr {
margin-top: 40px;
}


.galogo {
height: 20px;
}

.navImage{
display:inline-block;
height: 28px;
width: 28px;
border: 1px solid #333;
padding: 2px;
margin-left: 8px;
cursor: pointer;
}

.navImage img {
width: 24px;
height: 24px;
}

.browseTools{
display: inline-block;
position: absolute;
right: 60px;
top: 200px;
text-align: right;
}

.hcInvertedItems{
background-color: #eeeeee;
padding: 4px 0 0 14px;
}

.detailNavBtn{
display: inline-block;
font-weight: bold;
}

.hcDataSetHeaderLink{
cursor: pointer;
}

.datasetMetadataImg{
display: inline-block;
width: 200px;
}

.hcDescLine{
display: inline-block;
margin-bottom: 5px;
}

.yasgui span{
display: inline;
padding-right: unset;
}

.switchURI{
font-weight: bold;
margin-bottom: 10px;
}

