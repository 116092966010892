@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,700");
html,
body {
padding: 0;
margin: 0;
}

* {
box-sizing: border-box;
}

ul, ol {
padding: 0;
margin: 0;
}

li {
padding: 0;
margin: 0 0 0 1rem;
}

a {
text-decoration: none;
}

ul ul {
margin: 0 0 0 1em;
}

ul ul ul {
margin: 0 0 0 2em;
}

.hcClrBg_Info {
background-color: hsl(190deg, 70%, 40%);
}

.hcClrBg_InfoLight {
background-color: hsl(190deg, 60%, 95%);
}

.hcClrBg_Reward {
background-color: hsl(80deg, 60%, 53%);
}

.hcClrBg_RewardLight {
background-color: hsl(80deg, 60%, 80%);
}

.hcClrBg_Error {
background-color: hsl(0deg, 63%, 65%);
}

.hcClrBg_ErrorLight {
background-color: hsl(10deg, 100%, 80%);
}

.hcClrBg_Caution {
background-color: hsl(47deg, 68%, 64%);
}

.hcClrBg_CautionLight {
background-color: hsl(47deg, 68%, 80%);
}

.hcTxt_Caution {
color: hsl(19deg, 84%, 42%);
}

.hcClrBg_Grey05 {
background-color: hsl(0deg, 0%, 95%);
}

.hcClrBg_Grey10 {
background-color: hsl(0deg, 0%, 90%);
}

.hcClrBg_Grey15 {
background-color: hsl(0deg, 0%, 85%);
}

.hcClrBg_Grey20 {
background-color: hsl(0deg, 0%, 80%);
}

.hcClrBg_Grey30 {
background-color: hsl(0deg, 0%, 70%);
}

.hcClrBg_Grey70 {
background-color: hsl(0deg, 0%, 30%);
}

.hcClrBg_Grey80 {
background-color: hsl(0deg, 0%, 20%);
}

.hcClrBg_Grey90 {
background-color: hsl(0deg, 0%, 10%);
}

.hcClrTxt {
color: hsl(0deg, 0%, 10%);
}

.hcClrTxt_Grey {
color: hsl(0deg, 0%, 50%);
}

.hcClrTxt_Dark {
color: #000;
}

body {
background-color: #fff;
color: #3e3e3e;
}

a {
color: hsl(200deg, 90%, 30%);
}

a .hcIconLinkColor {
fill: hsl(200deg, 90%, 30%);
}

.colorBgGrey {
background-color: rgb(246, 246, 246);
}

.colorBgGreyLight {
background-color: hsl(0deg, 0%, 98%);
}

.colorBgGreyPlus {
background-color: #dddddd;
}

.hcTxtColorGreyMid {
color: hsl(0deg, 0%, 40%);
}

body {
font-family: "Roboto", sans-serif;
font-weight: 300;
font-size: 18px;
line-height: 140%;
font-style: normal;
color: hsl(0deg, 0%, 10%);
}

h1 {
font-size: 1.5em;
line-height: 130%;
font-weight: 700;
}

h2 {
font-size: 1.3em;
line-height: 130%;
font-weight: 700;
}

h3 {
font-size: 1em;
line-height: 130%;
font-weight: 700;
}

h4 {
font-size: 0.85em;
line-height: 130%;
font-weight: 300;
font-style: italic;
}

@media (min-width: 600px) {
body {
font-size: 18px;
line-height: 150%;
}
h1 {
font-size: 2em;
}
h1, h2, h3, h4 {
line-height: 150%;
}
}
h1, h2, h3, h4 {
margin-top: 0;
margin-bottom: 0.3em;
}

p {
margin-top: 0;
margin-bottom: 1em;
}

strong {
font-weight: 700;
}

.hcSmallTxt {
font-size: 0.85rem;
}

.hcMonospace {
font-family: monospace;
}

.hcNotBold {
font-weight: 300;
}

.icon {
width: 1.3rem !important;
height: auto !important;
}

.hcIconText {
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: flex-start;
}
.hcIconText .icon {
margin-right: 0.5rem;
}

.hcIcon svg {
display: block;
width: 2rem;
height: auto;
}

.hcIconFill {
fill: #3e3e3e;
}

.hcIconStroke {
stroke: #3e3e3e;
stroke-width: 3px;
}

.hcIconSmall svg {
width: 1rem;
}

.hcPointer {
cursor: pointer;
}

.hcIconSvg {
height: 0.7rem;
width: auto;
}

.hcBasicSideMargin {
padding-left: 2rem;
padding-right: 2rem;
}

.hcBasicPading {
padding: 1rem;
}

.hcMarginBottom05 {
margin-bottom: 0.5rem;
}

.hcMarginBottom1 {
margin-bottom: 1rem;
}

.hcMarginBottom1_5 {
margin-bottom: 1.5rem;
}

.hcMarginBottom2 {
margin-bottom: 2rem;
}

.hcMarginBottom3 {
margin-bottom: 3rem;
}

.hcMarginBottom4 {
margin-bottom: 4rem;
}

.hcMarginBottom5 {
margin-bottom: 5rem;
}

.hcMarginBottom15 {
margin-bottom: 15rem;
}

.hcMarginTop05 {
margin-top: 0.5rem;
}

.hcMarginTop1 {
margin-top: 1rem;
}

.hcMarginTop1_5 {
margin-top: 1.5rem;
}

.hcMarginTop2 {
margin-top: 2rem;
}

.hcMarginTop3 {
margin-top: 3rem;
}

.hcMarginTop4 {
margin-top: 4rem;
}

.hcMarginTop5 {
margin-top: 5rem;
}

.hcMarginBackground {
padding-top: 4rem;
padding-bottom: 4rem;
}

.hcNestedItemMargin1 > * {
margin-right: 1rem;
margin-bottom: 1rem;
}

.hcMarginLeft05 {
margin-left: 0.5rem;
}

.hcMarginLeft1 {
margin-left: 1rem;
}

.hcMarginLeft2 {
margin-left: 2rem;
}

.hcMarginLeft3 {
margin-left: 3rem;
}

.hcMarginRight05 {
margin-right: 0.5rem;
}

.hcMarginRight1 {
margin-right: 1rem;
}

.hcMarginRight2 {
margin-right: 2rem;
}

.hcMarginRight3 {
margin-right: 3rem;
}

.hcAlignHorizontal {
display: flex;
flex-direction: row;
}

.hcAlignVertical {
display: flex;
flex-direction: column;
}

.hcAlignLeft {
display: flex;
justify-content: flex-start;
}

.hcAlignRight {
display: flex;
justify-content: flex-end;
}

.hcAlignVertical.alignRight {
display: flex;
align-items: flex-end;
}

.hcAlignLeftRight {
display: flex;
justify-content: space-between;
}

.hcAlignCenter {
display: flex;
justify-content: center;
}

.hcAlignVertical.alignCenter {
display: flex;
align-items: center;
}

.hcRowLeft {
display: flex;
justify-content: flex-start;
align-items: center;
}

.hcRowLeftTop {
display: flex;
justify-content: flex-start;
align-items: flex-start;
}

.hcRowRight {
display: flex;
justify-content: flex-end;
align-items: center;
}

.hcRowRightTop {
display: flex;
justify-content: flex-end;
align-items: flex-start;
}

.hcRowJustify {
display: flex;
justify-content: space-between;
align-items: center;
}

.hcRowJustifyTop {
display: flex;
justify-content: space-between;
align-items: flex-start;
}

.hcTxtLeft {
text-align: left;
}

.hcTxtRight {
text-align: right;
}

.hcTxtCenter {
text-align: center;
}

.hcLists > * {
list-style: none;
margin-top: 0;
margin-left: 0;
margin-right: 0;
margin-bottom: 1.2rem;
padding-top: 0;
padding-left: 0;
padding-right: 0;
padding-bottom: 1.2rem;
border-bottom: 1px solid hsl(0deg, 0%, 85%);
}

.hcListsItemRow {
display: flex;
flex-direction: column;
margin-bottom: 1rem;
}
.hcListsItemRow > * {
margin-bottom: 1rem;
}

@media (min-width: 800px) {
.hcListsItemRow {
flex-direction: row;
}
.hcListsItemRow > * {
margin-bottom: 0;
}
}
.hcNoList li {
list-style: none;
margin-left: 0;
}

.hcList {
display: flex;
flex-direction: column;
}
.hcList li {
margin: 0.5rem 0 0.5rem 0;
}

.hcList > * {
display: flex;
flex-direction: column;
justify-content: space-between;
border-top: 1px solid hsl(0deg, 0%, 85%);
padding-top: 0.7rem;
padding-bottom: 0.7rem;
}

.hcList > *:hover {
background-color: hsl(0deg, 0%, 85%);
margin: 0 -1rem;
padding-left: 1rem;
padding-right: 1rem;
}

.hcListDisctinctLines > * {
border-top: none;
}

.hcListDisctinctLines > *:nth-child(odd) {
background-color: hsl(0deg, 0%, 95%);
margin: 0 -1rem;
padding-left: 1rem;
padding-right: 1rem;
}

.hcListDisctinctLines > *:nth-child(odd):hover {
background-color: hsl(0deg, 0%, 85%);
}

.hcListDisctinctLines > *:nth-child(even) {
background-color: none;
}

.hcList > * > * {
flex: 1;
margin-right: 1rem;
}

@media (min-width: 600px) {
.hcList > * {
flex-direction: row;
display: flex;
}
}
.hcListItemLong {
flex: 2;
}

.hcListHeader {
background: none;
border: none;
}

.hcListHeader:hover {
background: none;
border: none;
}

.hcListBasicResult {
cursor: pointer;
}

.hcRowCard {
background-color: #fff;
padding: 1rem;
margin: 0 -1rem 0.5rem -1rem;
border-radius: 2px;
}

.hcRowCard > * {
display: flex;
flex-direction: column;
justify-content: flex-start;
}

.hcListItemBlock {
padding: 0;
transition: background-color 0.3s;
margin-bottom: 1.5rem;
}

.hcListItemBlock > * {
padding: 1rem 2rem;
}

.hcListItemDPPBlock {
flex: 2;
display: flex;
flex-direction: column;
justify-content: space-between;
}

.hcKwic {
border-bottom: 3px dotted rgb(231, 218, 103);
}

.hcListItemThumb {
overflow-y: scroll;
display: flex;
align-items: flex-start;
justify-content: center;
background-color: #3b3b3b;
padding-top: 0.5rem;
}

.hcListItemThumb div {
height: 170px;
}

.hcListItemThumb img {
width: 200px !important;
height: auto !important;
}

.hcResultsHeader {
display: flex;
justify-content: space-between;
}

.hcCards {
display: grid;
grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
grid-gap: 2rem;
}

dl dt {
text-transform: uppercase;
font-size: 0.85rem;
}
dl dd {
margin: 0;
margin-bottom: 1rem;
}

.hcMessageBar {
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100%;
font-size: 0.85rem;
line-height: 140%;
font-style: italic;
background-color: hsl(0deg, 0%, 96%);
border-radius: 4px;
}

.hcMessageBar > * {
padding: 0.5rem;
}

.notificationInform {
color: #005466;
background-color: #ccf6ff;
}
.notificationInform .hcIconFill {
fill: #005466;
}
.notificationInform .hcIconStroke {
stroke: #005466;
}

.notificationCaution {
color: #967d1c;
background-color: white;
}
.notificationCaution .hcIconFill {
fill: #967d1c;
}
.notificationCaution .hcIconStroke {
stroke: #967d1c;
}

.notificationError {
color: #3b0b00;
background-color: #ffb2a1;
}
.notificationError .hcIconFill {
fill: #3b0b00;
}
.notificationError .hcIconStroke {
stroke: #3b0b00;
}

.notificationReward {
color: #476018;
background-color: #f1f8e5;
}
.notificationReward .hcIconFill {
fill: #476018;
}
.notificationReward .hcIconStroke {
stroke: #476018;
}

.hc2columns, .hc3columns, .hc4columns, .hc5columns, .hc6columns {
display: flex;
flex-direction: column;
}

.hc2columns, .hc3columns, .hc4columns, .hc5columns, .hc6columns > * {
flex-basis: 100%;
}

@media (min-width: 800px) {
.hc2columns, .hc3columns, .hc4columns, .hc5columns, .hc6columns {
flex-direction: row;
}
.hc2columns > * {
flex-basis: 50%;
}
.hc3columns > * {
flex-basis: 33%;
}
.hc4columns > * {
flex-basis: 25%;
}
.hc5columns > * {
flex-basis: 20%;
}
.hc6columns > * {
flex-basis: 15%;
}
}
.hcColumnsAuto {
display: flex;
flex-direction: column;
gap: 1rem;
width: 100%;
}
.hcColumnsAuto > * {
flex: 1;
}

@media (min-width: 800px) {
.hcColumnsAuto {
flex-direction: row;
gap: 2rem;
}
}
.hcCell--x2 {
flex: 2;
}

.hcCell--x3 {
flex: 3;
}

.hcCell--x4 {
flex: 4;
}

.hcCell--x5 {
flex: 5;
}

button {
font-size: 0.7rem;
padding: 0.5rem;
border-radius: 4px;
background-color: rgba(0, 0, 0, 0.07);
border: 1px solid rgba(0, 0, 0, 0.09);
cursor: pointer;
font-weight: 700;
margin: 0 0.3rem;
max-height: 40px;
}

.hcImageButttons {
max-height: 35px;
max-width: 35px;
background-color: rgba(0, 0, 0, 0.07);
border: 1px solid rgba(0, 0, 0, 0.09);
padding: 0.25rem;
}

.hc-loud {
padding: 0.8rem;
font-size: 1rem;
}

.hc-small {
padding: 0.3rem;
font-size: 0.6rem;
}

.hc-subtle {
background: none;
border: none;
box-shadow: none;
color: hsl(200deg, 90%, 30%);
}

.hcfixedSideButton {
width: 2rem;
padding: 0.2rem;
border-radius: 0 4px 4px 0;
}

.hcToggle button {
margin: 0 0rem;
border-radius: 0px;
border-right: 0px;
}
.hcToggle > *:first-child {
border-radius: 4px 0 0 4px;
}
.hcToggle > *:last-child {
border-radius: 0 4px 4px 0;
border-right: 1px solid rgba(0, 0, 0, 0.09);
}

.buttonRemove:before {
content: "";
/*background: url("Icons/icon-util-close.html");*/
width: 20px;
height: 20px;
display: block;
}

.hcDropList .hcDropList__list {
display: none;
flex-direction: column;
position: absolute;
}
.hcDropList .hcDropList__list a {
padding: 0.5rem;
}

.hcDropList:hover .hcDropList__list {
display: flex;
z-index: 150;
}

input, select, option, textarea {
width: 100%;
padding: 0.5rem;
border: 1px solid #e1ebf3;
border-radius: 4px;
font-size: 1rem;
font-weight: 300;
color: #3e3e3e;
height: 40px;
}

input[type=checkbox] {
width: 20px;
height: 20px;
}

.hcForm input, .hcForm select, .hcForm option, .hcForm textarea {
background-color: #f0f5f9;
max-width: 480px;
background-color: #f0f5f9;
}
.hcForm input[type=checkbox] {
background-color: #f0f5f9;
max-width: 30px;
background-color: #f0f5f9;
}
.hcForm textarea {
height: auto;
min-height: 150px;
}

input::placeholder {
color: hsl(0deg, 0%, 50%);
font-style: italic;
font-size: 0.85rem;
}

.hcLabel {
color: hsl(0deg, 0%, 30%);
text-transform: uppercase;
font-size: 0.85rem;
}

.hcLabelBold {
color: hsl(0deg, 0%, 30%);
text-transform: uppercase;
font-size: 0.85rem;
font-weight: 700;
}

.hcList2 {
margin-left: 0;
margin-right: 0;
padding-left: 0;
padding-right: 0;
}
.hcList2 li {
margin-left: 0;
margin-right: 0;
padding-left: 0;
padding-right: 0;
}

.hcList2NoIcon {
list-style: none;
}

.hcStickyFooter {
position: fixed;
width: 100%;
min-width: 40px;
bottom: 0;
}

.hcStickyFooterLook {
background-color: #fff;
padding-top: 1rem;
padding-bottom: 1rem;
border-top: 1px solid hsl(0deg, 0%, 85%);
}

.hcTextSnippit::before {
content: " ...";
}

.hcTextSnippit::after {
content: "... ";
}

.hcDatasetEntity {
font-size: 0.85rem;
padding: 0rem 0 0rem 0.5rem;
border: 1px solid #e7e7e7;
border-radius: 0.5rem 0 0 0.5rem;
margin: 0 0.5rem 0.5rem 0;
display: flex;
flex-direction: row;
justify-content: space-between;
}

.hcDatasetEntityAmount {
border-radius: 3px;
padding: 0 0.5rem;
margin: -1px -2px -1px 0;
background-color: #e7e7e7;
color: #3e3e3e;
min-width: 3.5rem;
text-align: right;
}

.hcPartnerLogo img {
width: 100px !important;
height: auto !important;
}

.hcDensity {
width: 100%;
min-width: 100px;
background-color: rgb(227, 227, 227);
}

.hcDensityBar {
background-color: rgb(58, 165, 176);
font-size: 0.5rem;
color: #fff;
padding: 0 0 0 0.5rem;
}

.hcUriType {
font-family: monospace;
font-size: 0.85rem;
}

.hcBreakAll {
overflow-wrap: break-word;
word-break: break-all;
hyphens: auto;
}

.hcBarDataset {
display: flex;
flex-direction: column;
padding-bottom: 1rem;
}

@media (min-width: 600px) {
.hcBarDataset {
flex-direction: row;
}
span {
display: flex;
flex-direction: row;
align-items: baseline;
padding-right: 1rem;
}
select {
background-color: none;
}
}
.hcBorderBottom {
border-bottom: 1px solid #ededed;
}

.hcMapPlaceholder {
width: 100%;
height: 250px;
/*background-image: url("../images/map-placeholder.jpg");*/
background-size: cover;
background-position: center;
}

hr {
border: none;
height: 1px;
color: rgba(0, 0, 0, 0.15); /* old IE */
background-color: rgba(0, 0, 0, 0.15); /* Modern Browsers */
}

.hcStickOutBox {
margin: -1rem;
padding: 1rem;
background-color: hsl(0deg, 0%, 95%);
}

.hcStickOutBoxAside {
margin: 0 -1rem;
padding: 0 1rem;
background-color: hsl(0deg, 0%, 95%);
}

.hcRoundedCorners {
border-radius: 4px;
}

.hcRoundedCornersTop {
border-radius: 4px 4px 0 0;
}

.hcTextTruncate {
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: 200px;
}

.hcMsg--info {
background-color: hsl(201deg, 100%, 95%);
border: 1px solid hsl(201deg, 50%, 80%);
color: hsl(201deg, 50%, 30%);
border-radius: 4px;
position: absolute;
width: 100%;
max-width: 720px;
padding: 1rem;
box-shadow: 2px 2px 2px hsl(0deg, 0%, 85%);
}

.hcPointer {
cursor: pointer;
}

.hcContentContainer {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
}

.hcContentContainer > * {
width: 100%;
max-width: 1200px;
}

.hcBlockText {
width: 100%;
}
.hcBlockText ol, .hcBlockText ul {
margin-left: 1rem;
}

.hcBlockText > * {
width: 100%;
}

.hcHeadSpace {
height: 25vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
}

@media (min-width: 800px) {
.hcHeadSpace {
flex-direction: row;
align-items: center;
justify-content: space-between;
}
}
.hcBlockScroll {
height: 100vh;
overflow-y: auto;
}

.hcBlockHeroWithTeaser {
height: 66vh;
display: flex;
justify-content: center;
}

.hcLayoutBasicMainAside, .hcLayoutBasicMainFixedAside {
display: flex;
flex-direction: column;
align-items: flex-start;
}

.hcLayoutAside {
width: 100%;
}

.hcLayoutMain {
width: 100%;
}

@media (min-width: 800px) {
.hcLayoutBasicMainAside {
flex-direction: row;
}
.hcLayoutAside {
width: 300px;
}
.hcLayoutMain {
width: calc(100vw - 300px);
min-width: calc(800px - 300px);
}
}
@media (min-width: 800px) {
.hcLayoutBasicMainFixedAside {
flex-direction: row;
justify-content: flex-end;
}
.hcLayoutBasicMainFixedAside .hcLayoutAside {
width: 300px;
position: fixed !important;
top: 0;
left: 0;
bottom: 0;
}
.hcLayoutBasicMainFixedAside .hcLayoutMain {
width: calc(100vw - 300px);
margin-left: 300px;
}
.hcLayoutBasicMainFixedAside .asideWithProductBar {
display: flex;
}
.hcLayoutBasicMainFixedAside .asideWithProductBar > *:last-child {
margin-left: 4rem;
}
.hcLayoutBasicMainFixedAside .hcStickyFooter {
width: calc(100vw - 300px);
}
}
@media (min-width: 1300px) {
.hcLayoutBasicMainFixedAside {
flex-direction: row;
justify-content: flex-end;
}
.hcLayoutBasicMainFixedAside .hcLayoutAside {
width: 400px;
}
.hcLayoutBasicMainFixedAside .hcLayoutMain {
width: calc(100vw - 400px);
margin-left: 400px;
}
.hcLayoutBasicMainFixedAside .asideWithProductBar > *:last-child {
margin-left: 4rem;
width: 100%;
}
.hcLayoutBasicMainFixedAside .hcStickyFooter {
width: calc(100vw - 400px);
}
}
.hcLayoutFacet-Result {
display: flex;
flex-direction: column;
align-items: flex-start;
}

.hcLayoutFacets {
width: 100%;
}

.hcLayoutResults {
width: 100%;
}

.hcLayoutFacetsToggel {
display: flex;
height: 230px;
flex-direction: row;
overflow-x: auto;
background-color: hsl(0deg, 0%, 98%);
}
.hcLayoutFacetsToggel div {
width: 250px;
margin-right: 3rem;
}

#showFacets {
position: fixed;
top: 45vh;
left: 0;
}

@media (min-width: 800px) {
.hcLayoutFacet-Result {
flex-direction: row;
}
.hcLayoutFacets {
width: 350px;
}
.hcLayoutResults {
width: calc(100% - 350px);
}
.hcLayoutFacetsToggel {
display: block;
overflow-x: none;
height: auto;
flex-direction: column;
background: none;
}
.hcLayoutFacetsToggel div {
width: 100%;
margin-right: auto;
}
#showFacets {
display: none;
position: fixed;
}
}
.hcThumbsRow {
display: flex;
flex-direction: row;
}

.hcThumbsRow img {
margin: 1rem 0 1rem 1rem;
cursor: pointer;
}

.hclayoutTextImageDivider {
display: flex;
flex-direction: row;
height: calc(100vh - 150px);
}

.hcViewer {
width: 45%;
}

.hcMeta {
max-width: 250px;
}

.hcText {
display: flex;
flex-direction: row;
justify-content: center;
}

.hcText > * {
max-width: 550px;
margin-bottom: 500px;
}

.hcLayout-right-sidebar {
display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
}

.hcLayout-right-sidebar-main {
width: 100%;
}

.hcLayout-right-sidebar-aside {
width: 100%;
}

@media (min-width: 800px) {
.hcLayout-right-sidebar {
flex-direction: row;
}
.hcLayout-right-sidebar-main {
width: calc(100% - 250px - 2rem);
}
.hcLayout-right-sidebar-aside {
width: 250px;
}
}
.hcSidebar div {
margin-bottom: 1.5rem;
}

.hcSpitscreen {
display: flex;
flex-direction: column;
}

.hcSpitscreen > * {
display: flex;
flex-direction: column;
justify-content: flex-start;
}

@media (min-width: 1400px) {
.hcSpitscreen {
flex-direction: row;
}
}
.hcLayoutWideCols {
display: flex;
flex-direction: column;
width: 100%;
}
.hcLayoutWideCols > * {
width: 100%;
max-width: 50vw;
}

@media (min-width: 800px) {
.hcLayoutWideCols {
flex-direction: row;
}
.hcWideColsFixedWidth {
width: 100%;
max-width: 25vw;
}
}
.hcLayoutWideColsInner {
width: 100%;
}

.hcLayoutStaticAside {
display: flex;
flex-direction: row;
flex-wrap: wrap;
}

.hcLayoutStaticAsideM {
width: 100%;
background-color: rgb(136, 208, 219);
max-height: 50vh;
}

.hcLayoutStaticAsideL {
width: 50%;
background-color: rgb(236, 208, 219);
}

.hcLayoutStaticAsideR {
width: 50%;
background-color: rgb(36, 208, 219);
}

@media (min-width: 1100px) {
.hcLayoutStaticAsideM {
width: 50%;
max-height: none;
order: 2;
}
.hcLayoutStaticAsideL {
width: 25%;
order: 1;
}
.hcLayoutStaticAsideR {
width: 25%;
order: 3;
}
}
.navigation a {
color: #3e3e3e;
font-weight: 700;
font-size: 14px;
padding: 0.2rem;
margin-right: 0.5rem;
}
.navigation .navigation-sub > * {
font-weight: 300;
}
.navigation li {
list-style: none;
}
.navigation .navigation-secundairy-items {
display: none;
}

.hcPageHeaderSimple {
display: flex;
flex-direction: column;
justify-content: space-between;
font-size: 0.9rem;
width: 100%;
}
.hcPageHeaderSimple nav {
display: flex;
flex-direction: row;
height: 100%;
align-items: baseline;
line-height: 130%;
}
.hcPageHeaderSimple nav a {
height: 100%;
padding: 1.5rem 1rem;
display: flex;
align-items: center;
}
.hcPageHeaderSimple nav a:hover {
background-color: rgba(255, 255, 255, 0.11);
}
.hcPageHeaderSimple nav a:first-child {
margin-left: -1rem;
}
.hcPageHeaderSimple .hcMenuButton {
border: none;
background: none;
font-size: 0.9rem;
color: #fff;
display: flex;
flex-direction: row;
align-items: center;
margin-left: 2rem;
}
.hcPageHeaderSimple .hcMenuButton img {
width: 0.7rem;
height: 0.7rem;
margin-left: 0.5rem;
}

.hcPageHeaderSimple > * {
height: 100%;
display: flex;
align-items: center;
}

.hcSecundairNav {
display: none;
}

@media (min-width: 600px) {
.hcPageHeaderSimple {
flex-direction: row;
}
.hcSecundairNav {
display: flex;
}
.hcMenuButton {
display: none;
}
}
.hcBrand {
display: flex;
flex-direction: row;
justify-content: flex-start;
height: 100%;
}

.hcBrandLogo img {
height: 33px;
width: auto;
}

.hcBrandName {
padding: 1.5rem 1rem 1rem 1rem;
font-weight: 700;
}

@media (min-width: 600px) {
.hcBrandLogo img {
height: 60px;
width: auto;
}
}
.hcFullscreenMenu {
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
font-size: 1.5rem !important;
align-items: flex-start;
justify-content: flex-start;
}
.hcFullscreenMenu nav {
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
min-height: 50vh;
}
.hcFullscreenMenu nav a {
padding: 0 0rem;
}
.hcFullscreenMenu #hcMenuButton {
position: fixed;
top: 30px;
right: 30px;
}
.hcFullscreenMenu .hcSecundairNav {
display: flex;
}
.hcFullscreenMenu nav a:first-child {
margin-left: 0rem;
}
.hcFullscreenMenu .hcBrand {
margin-left: 0rem;
margin-top: 6rem;
margin-bottom: 3rem;
}
.hcFullscreenMenu .hcPageHeaderSimple {
flex-direction: column;
}

.hcTabs {
display: flex;
width: 100%;
}
.hcTabs .hcSelectedTab {
font-weight: 700;
background-color: hsl(0deg, 0%, 95%);
}

.hcTabsVert {
flex-direction: column;
}
.hcTabsVert .hcTabLabels {
display: flex;
flex-direction: column;
}

.hcTabsHoriz {
flex-direction: column;
}
.hcTabsHoriz .hcTabLabels {
display: flex;
flex-direction: row;
}

.hcTabLabels {
width: 100%;
position: relative;
z-index: 100;
}

.hcTabAllContent {
width: 100%;
position: relative;
z-index: 10;
background-image: linear-gradient(hsl(0deg, 0%, 95%), #fff 50px);
}

@media (min-width: 500px) {
.hcTabsVert {
flex-direction: row;
}
.hcTabsVert .hcTabLabels {
width: 20%;
}
.hcTabsVert .hcTabAllContent {
width: 80%;
}
}
.hcTabContent {
width: 100%;
}

.hcTabLabel {
cursor: pointer;
padding: 0.5rem 2rem;
display: flex;
flex-direction: row;
align-items: center;
}

.hcFormItem {
display: flex;
flex-direction: column;
width: 100%;
}

.hcFormItem > * {
width: 100%;
}

.hcFeedbackError {
border-bottom: 3px solid #d42600 !important;
}

.hcFeedbackSucces {
border-bottom: 3px solid #d42600 !important;
}

.hcFormItemRow {
display: flex;
flex-direction: column;
justify-content: space-between;
}

.hcFormItemExtra {
width: 100%;
}

@media (min-width: 1280px) {
.hcFormItemRow {
flex-direction: row;
}
.hcFormItemExtra {
width: 60%;
display: flex;
align-items: center;
padding-left: 1rem;
}
}
.hcProvenanceComp {
width: 100%;
display: flex;
flex-direction: column;
display: none;
}
.hcProvenanceComp textarea {
height: 30px !important;
}

.hcFormStack {
display: flex;
flex-direction: column;
border-top: 1px solid #e8e8e8;
padding-top: 2rem;
width: 100%;
}

.hcFormStack > * {
width: 100%;
display: flex;
flex-direction: column;
}

.hcStackInfo {
margin-bottom: 2rem;
margin-right: 2rem;
}

@media (min-width: 800px) {
.hcFormStack {
flex-direction: row;
}
.hcStackInfo {
width: calc(30% - 2rem);
}
.hcStackFormItems {
width: 70%;
padding-top: 1.5rem;
}
}
.hcProductBar {
width: 100vw;
padding: 0 1.5rem;
display: flex;
justify-content: flex-start;
background-color: rgb(73, 73, 73);
color: #3e3e3e;
}
.hcProductBar .icon {
width: 1rem;
height: auto;
}
.hcProductBar .logo {
width: 3rem;
height: auto;
margin-left: -1rem;
}

@media (min-width: 800px) {
.hcProductBar {
position: fixed;
z-index: 999;
top: 0;
left: 0;
width: 4rem;
overflow-y: hidden;
height: 100vh;
flex-direction: column;
align-items: flex-start;
}
.hcProductBar .icon {
width: 1.5rem;
margin-right: 1rem;
}
.hcProductBar nav {
display: flex;
flex-direction: column;
}
.hcProductBar nav > * {
margin-bottom: 2rem;
width: 200px;
color: #3e3e3e;
}
.hcProductBar + .hcContentContainer {
margin-left: 4rem;
width: calc(100% - 4rem);
}
}
@media (max-width: 800px) {
.hcProductBar .hcHeadSpace {
height: auto;
}
}
.hcFacet {
margin-bottom: 3rem;
max-width: 250px;
font-size: 0.85rem;
}

.hcFacetItems {
display: flex;
flex-direction: column;
}

.hcFacetItem {
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
}
.hcFacetItem .hcFacetItemSpan {
display: flex;
flex-direction: row;
align-items: center;
height: 15px;
}
.hcFacetItem .hcFacetItemSpan input[type=checkbox] {
width: 15px;
background-color: rgb(238, 224, 144);
}

.hcFacetItemSelected {
font-weight: 700;
}

.hcFacetTitle {
text-transform: uppercase;
font-size: 0.85rem;
font-weight: 700;
display: flex;
flex-direction: row;
justify-content: space-between;
}

.hcFacetCount {
color: hsl(0deg, 0%, 40%);
}

.hcFacetSearch {
display: flex;
justify-content: space-between;
}
.hcFacetSearch input {
border-radius: 4px 0 0 4px;
border-right: 0px;
}
.hcFacetSearch button {
border-radius: 0 4px 4px 0;
}

.hcSelectedFacet {
font-size: 0.85rem;
padding: 0.2rem 0.5rem;
background-color: rgb(246, 246, 246);
border-radius: 3px;
margin: 0 0.5rem 0.5rem 0;
cursor: pointer;
}

.hcSelectedFacet:after {
/*content: url(../images/icons/icon-delete.svg);*/
margin-left: 0.3rem;
opacity: 0.4;
}

.hcSelectedFacetType {
color: rgba(0, 0, 0, 0.4);
font-style: italic;
margin-right: 0.3rem;
}

.hcFacetFilter input {
padding: 0.3rem;
font-size: 0.85rem;
height: auto;
border: 1px solid #ebebeb;
}

.hcFacetFilter input::placeholder {
font-style: italic;
color: rgba(0, 0, 0, 0.4);
}

.hcIconHelp img {
width: 1.3rem;
height: auto;
cursor: pointer;
}

.hcFacetHelp {
display: none;
position: absolute;
background-color: rgb(37, 163, 154);
padding: 0.5rem;
width: 250px;
}

.hcPagination {
display: flex;
flex-direction: row;
justify-content: center;
flex-wrap: wrap;
}

.hcPagination > * {
margin: 0 0.5rem;
}

.hcPagination a {
padding: 0.5rem;
}

.hcModal {
background-color: rgba(0, 0, 0, 0.85);
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 10000;
}

.hcModelContentWrap {
background-color: #ffffff;
height: calc(100vh - 6rem);
width: calc(100vw - 4rem);
margin: 6rem 0;
overflow-y: scroll;
}

.hcQueryBuilder {
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
font-size: 1.1rem;
font-weight: 700;
}

.hcQBuildBlock {
padding: 0.2rem 0.3rem;
display: flex;
align-items: center;
margin-left: -1px;
height: 2.5rem;
}
.hcQBuildBlock .hcIconFill {
fill: hsl(0deg, 0%, 70%) !important;
}

.hcQBuildBlock:hover {
box-shadow: 2px 2px 2px hsl(0deg, 0%, 85%);
}

.hcQueryBuilderRow {
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
margin-bottom: 1rem;
}

.hcQBuildBlockInt {
border: 1px solid hsl(0deg, 0%, 80%);
cursor: pointer;
}

.hcQBuildBlockChoice {
display: flex;
flex-direction: column;
margin-bottom: -7.5rem;
}

.hcQBuildBlockChoice > * {
border-top: 1px solid hsl(0deg, 0%, 80%);
border-left: 1px solid hsl(0deg, 0%, 80%);
border-right: 1px solid hsl(0deg, 0%, 80%);
cursor: pointer;
padding: 0.2rem 0.3rem;
width: 8rem;
}

.hcQBuildBlockChoice > *:last-child {
border-bottom: 1px solid hsl(0deg, 0%, 80%);
border-radius: 0 0 4px 4px;
}

.hcQBuildBlockChoice > *:first-child {
border-radius: 4px 4px 0 0;
}

.hcQBuildBlockBrowse {
background-color: hsl(0deg, 0%, 90%);
height: 50vh;
margin-bottom: calc(2.5rem - 50vh);
z-index: 200;
padding: 0.2rem 0.3rem;
width: 20rem;
display: flex;
flex-direction: column;
justify-content: flex-start;
box-shadow: 2px 2px 2px hsl(0deg, 0%, 85%);
}
.hcQBuildBlockBrowse input {
font-size: 0.85rem;
border: 1px solid hsl(0deg, 0%, 80%);
}
.hcQBuildBlockBrowse .hcList {
font-weight: 300;
font-size: 0.85rem;
flex: 1;
overflow-y: auto;
width: 100%;
}
.hcQBuildBlockBrowse .hcList > * {
margin: 0;
padding-left: 1rem;
}

.hcCodeEditor {
display: flex;
flex-direction: row;
}
.hcCodeEditor textarea {
border: none;
padding: 0;
min-height: 40vh;
}

.hccodeTxt {
font-family: "Lucida Console", Monaco, monospace;
font-weight: 300;
font-size: 0.85rem;
line-height: 1.5rem;
}

.hcLineNumbers {
display: flex;
flex-direction: column;
text-align: right;
font-size: 0.7rem;
}
.hcLineNumbers > * {
text-align: right;
display: block;
}

.hcLogicBox {
width: 100%;
}

.hcLogicBox-Row {
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
margin-bottom: 0.5rem;
margin-left: -1.5rem;
padding: 0.5rem;
}

.hcLogicBox-RowEditDelete {
display: flex;
flex-direction: row;
justify-content: flex-end;
}
.hcLogicBox-RowEditDelete > * {
margin-right: 1rem;
}

.hcLogicBox-group {
margin-bottom: 1rem;
border-left: 5px solid hsl(0deg, 0%, 50%);
padding-left: 2rem;
}

.hcLogicBox-group-operator {
font-weight: 700;
margin-bottom: 1rem;
margin-left: -0.5rem;
color: hsl(0deg, 0%, 50%);
}

.hcTree {
margin: 0;
padding: 0;
}
.hcTree li {
list-style: none;
margin-top: 0;
margin-left: 0;
margin-right: 0;
padding: 0;
display: flex;
flex-direction: column;
}
.hcTree li ul {
margin: 0;
}
.hcTree li ul li {
margin-top: 0;
margin-right: 0;
margin-left: 2rem;
}
.hcTree li:hover {
font-weight: 700;
}
.hcTree li:hover ul {
font-weight: 300;
}

.hcTreeIcon {
width: 25px;
height: auto;
}

.hcTreeListGroup {
display: flex;
flex-direction: row;
}

.hcMirador {
width: 100%;
height: 100%;
}
.hcMirador img {
width: 100%;
height: auto;
}

html {
font-size: 100%;
}

body {
margin: 0;
padding: 0;
}

@media print {
body > *:not(main) {
display: none;
}
}
[href="#main-content"] {
position: absolute;
top: 0;
right: 100%; /* moves off screen */
}

[href="#main-content"]:focus {
right: auto;
}

.incCard {
position: relative;
}

.incCard a {
text-decoration: none;
}

.incCard a::after {
content: "";
position: absolute;
left: 0;
top: 0;
right: 0;
bottom: 0;
}

.incCard a:focus {
text-decoration: underline;
}

.brandName {
font-weight: 700;
}

.brandName img {
height: 50px;
width: auto !important;
}

.chBrandHuc .bgColorBrand1 {
background-image: -webkit-linear-gradient(25deg, #03dbe8 40%, #ddf279 100%) !important;
}
.chBrandHuc .colorBgGrey {
background-color: #edf7f3 !important;
}

.chBrandHuygens .bgColorBrand1 {
background-image: -webkit-linear-gradient(25deg, #082045 40%, #082045 100%) !important;
}
.chBrandHuygens .colorBgGrey {
background-color: #efefef !important;
}
body {
background-color: #fff;
color: #3e3e3e;
}

a, .ga-searcChoiceLink {
color: hsl(200deg, 90%, 30%);
}

a .hcIconLinkColor {
fill: hsl(200deg, 90%, 30%);
}

.colorBgGrey {
background-color: rgb(246, 246, 246);
}

.colorBgGreyLight {
background-color: hsl(0deg, 0%, 98%);
}

.colorBgGreyPlus {
background-color: #dddddd;
}

.hcTxtColorGreyMid {
color: hsl(0deg, 0%, 40%);
}

.hcClrBg_Info {
background-color: hsl(190deg, 70%, 40%);
}

.hcClrBg_InfoLight {
background-color: hsl(190deg, 60%, 95%);
}

.hcClrBg_Reward {
background-color: hsl(80deg, 60%, 53%);
}

.hcClrBg_RewardLight {
background-color: hsl(80deg, 60%, 80%);
}

.hcClrBg_Error {
background-color: hsl(0deg, 63%, 65%);
}

.hcClrBg_ErrorLight {
background-color: hsl(10deg, 100%, 80%);
}

.hcClrBg_Caution {
background-color: hsl(47deg, 68%, 64%);
}

.hcClrBg_CautionLight {
background-color: hsl(47deg, 68%, 80%);
}

.hcTxt_Caution {
color: hsl(19deg, 84%, 42%);
}

.hcClrBg_Grey05 {
background-color: hsl(0deg, 0%, 95%);
}

.hcClrBg_Grey10 {
background-color: hsl(0deg, 0%, 90%);
}

.hcClrBg_Grey15 {
background-color: hsl(0deg, 0%, 85%);
}

.hcClrBg_Grey20 {
background-color: hsl(0deg, 0%, 80%);
}

.hcClrBg_Grey30 {
background-color: hsl(0deg, 0%, 70%);
}

.hcClrBg_Grey70 {
background-color: hsl(0deg, 0%, 30%);
}

.hcClrBg_Grey80 {
background-color: hsl(0deg, 0%, 20%);
}

.hcClrBg_Grey90 {
background-color: hsl(0deg, 0%, 10%);
}

.hcClrTxt {
color: hsl(0deg, 0%, 10%);
}

.hcClrTxt_Grey {
color: hsl(0deg, 0%, 50%);
}

.hcClrTxt_Dark {
color: #000;
}

.hcGaHeaderGen {
display: flex;
flex-direction: row;
justify-content: flex-start;
flex-wrap: wrap;
gap: 0 2rem;
}
.hcGaHeaderGen > * {
display: flex;
justify-content: flex-start;
align-items: center;
}

.hcGaHeaderGen__Logo {
padding-top: 0rem;
padding-bottom: 0rem;
}
.hcGaHeaderGen__Logo img {
height: 60px;
width: auto;
}

.hcGaHeaderGen__ToolName {
font-weight: 700;
padding-right: 1rem;
flex-grow: 1;
}

.hcGaHeaderGen__Nav {
border-left: 1px solid hsl(49deg, 99%, 44%);
}
.hcGaHeaderGen__Nav a {
color: #000;
border-right: 1px solid hsl(49deg, 99%, 44%);
background-color: #efc501;
height: 100%;
display: flex;
align-items: center;
padding: 0 1rem;
transition: background-color 0.4s;
}
.hcGaHeaderGen__Nav a:hover {
background-color: hsl(49deg, 99%, 44%);
}
.hcGaHeaderGen__Nav button {
border-right: 1px solid hsl(49deg, 99%, 44%);
background-color: #efc501;
height: 100%;
transition: background-color 0.4s;
}

.hcGaHeader__Login {
justify-content: flex-end;
padding-right: 1.5rem;
}
.hcGaHeader__Login .user_icon {
height: 1rem;
width: auto;
margin-right: 0.5rem;
opacity: 0.4;
}
.hcGaHeader__Login button {
margin-left: 2rem;
}

.hcGaHeader__dropdown button {
background: none;
font-size: 18px;
font-weight: 300;
max-height: 300px;
height: 100%;
color: #000;
border-top: none;
border-left: none;
border-bottom: none;
}
.hcGaHeader__dropdown button img {
width: 8px;
height: auto;
transform: translateY(-5px);
}
.hcGaHeader__dropdown .hcDropList__list {
border-top: 1px solid hsl(49deg, 99%, 44%);
box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.2);
}
.hcGaHeader__dropdown .hcDropList__list a {
flex-direction: column;
display: flex;
align-items: flex-start;
border-bottom: 1px solid hsl(49deg, 99%, 44%);
max-width: 300px;
padding: 0.7rem 0.5rem;
}

@media (max-width: 574px) {
.hcGaHeaderGen__Nav {
border-top: 1px solid hsl(49deg, 99%, 44%);
flex-grow: 1;
width: 100%;
}
}
@media (max-width: 841px) {
.hcGaHeader__Login {
border-top: 1px solid hsl(49deg, 99%, 44%);
flex-grow: 1;
padding: 0.4rem;
}
}
.navSubMouseOver li ul {
max-height: 0;
overflow: hidden;
transition: max-height 0.9s ease-out;
}
.navSubMouseOver li:hover ul {
max-height: 1500px;
transition: max-height 0.9s ease-in;
}
.navSubMouseOver .current-menu-parent ul {
max-height: 3000px;
}
.layout_3col_vertNav {
display: grid;
width: 100%;
margin-left: auto;
margin-right: auto;
max-width: 1800px;
box-sizing: content-box;
}

@media (min-width: 1000px) {
.layout_3col_vertNav {
grid-template-columns: [leftt] 300px [col2] auto [rightt];
grid-template-rows: [topp] auto [row2] auto [bott];
}
#colNav {
grid-column: leftt/col2;
grid-row: topp/bott;
}
#colContent {
grid-column: col2/rightt;
grid-row: topp/row2;
}
#colContentWide {
grid-column: col2/rightt;
grid-row: topp/bott;
}
#colAside {
grid-column: col2/rightt;
grid-row: row2/bott;
}
}
@media (min-width: 1300px) {
.layout_3col_vertNav {
grid-template-columns: [leftt] 300px [col2] auto [colEnd] 300px [rightt];
grid-template-rows: [topp] auto [bott];
}
#colNav {
grid-column: leftt/col2;
grid-row: topp/bott;
}
#colContent {
grid-column: col2/colEnd;
grid-row: topp/bott;
}
#colContentWide {
grid-column: col2/rightt;
grid-row: topp/bott;
}
#colAside {
grid-column: colEnd/rightt;
grid-row: topp/bott;
}
}
html {
scroll-behavior: smooth;
}

.gaLayout_home {
display: flex;
flex-direction: column;
min-height: 100vh;
}

.ga_about {
background-color: #efc501;
margin-top: 60px;
flex: 1;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
}
.ga_about .ga_about__content {
padding-top: 5rem;
padding-bottom: 1rem;
}
.ga_about h1 {
font-size: 1.3rem;
}

@media (min-width: 600px) {
.ga_about > * {
max-width: 600px;
}
.ga_about h1 {
font-size: 1.6rem;
}
}
.ga_cards {
background-color: hsl(0deg, 0%, 94%);
flex: 1;
/*background-image: url(../images/ga_bg3.png);*/
padding-bottom: 7rem;
}
.ga_cards h2 {
margin-top: 6rem;
}

.ga-org-homeCard {
background-color: #ffffff;
padding: 2rem;
border-radius: 6px;
}
.ga-org-homeCard a {
font-size: 0.85rem;
margin-right: 1rem;
}
.ga-org-homeCard .ga-org-homeCard-r {
margin-right: -2rem;
text-align: right;
}
.ga-org-homeCard .ga-org-homeCard-r img {
width: 200px;
}

@media (min-width: 1000px) {
.gaCardSplit {
flex-direction: row;
display: flex;
}
}
@media (min-width: 1000px) {
.gaLayout_home {
flex-direction: row;
}
.ga_about {
margin-top: 0;
flex: 1;
}
.ga_about .ga_about__content {
position: sticky;
top: 0;
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100vh;
padding-top: 8rem;
}
.ga_cards {
margin-top: 40px;
}
.ga_cards .hcCards {
grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}
}
@media (min-width: 1500px) {
.ga_cards {
flex: 2;
}
}
.hcGaHeaderGenStick {
position: fixed;
width: 100vw;
top: 0;
z-index: 200;
border-bottom: 1px solid hsl(0deg, 0%, 94%);
}

@media (min-width: 1000px) {
.ga-homePartners {
margin-top: 1rem;
background-color: #efc501;
/*background-image: url(../images/ga_partners.jpg);*/
background-blend-mode: darken;
background-repeat: no-repeat;
background-size: contain;
width: 300px;
height: 150px;
opacity: 0.5;
}
}
.gaLineButton {
border-radius: 10px;
border: 1px solid #3e3e3e;
background: none;
color: #3e3e3e;
padding: 0.1rem 0.4rem;
font-size: 0.85rem;
margin-right: 0.4rem;
margin-bottom: 0.4rem;
display: inline-block;
}
.gaLineButton a {
color: #3e3e3e;
}

.bgColorBrand1 {
background-image: none !important;
background-color: #efc501;
}

.bgColorBrand2 {
background-image: none !important;
background-color: #058f76;
color: #fff;
}

.bgColorBrand2 a {
color: #fff;
}

.colorBgGrey {
background-color: hsl(0deg, 0%, 96%) !important;
}

.hcBrandLogo img {
height: 50px;
}

.hcPageHeaderSimple a {
color: #000;
}

.hcGaHeader {
width: 100%;
display: flex;
}
.hcGaHeader > * {
display: flex;
align-items: center;
justify-content: center;
padding: 0 1rem;
}

.hcGaHeaderLogo {
padding-left: 0;
}

.hcGaHeaderLogo img {
width: auto;
height: 65px;
}

.hcGaHeaderToolName {
font-weight: 700;
border-right: 1px solid rgba(0, 0, 0, 0.16);
}

.hcGaTabsLoudness1 a {
color: #3e3e3e;
border-bottom: 3px solid #fff;
margin-left: 1rem;
}

.hcSelected {
font-weight: 700;
border-bottom: 3px solid #058f76 !important;
}

.hcSelectedListItem {
background-color: #058f76;
font-weight: 700;
color: #fff;
padding-left: 1rem;
margin-left: -1rem;
}

.hcleftMark {
border-left: 5px solid #058f76 !important;
}

.hcMaxhalf {
height: 40vh !important;
overflow-y: scroll;
}

@media (min-width: 800px) {
.hcMaxhalf {
height: 80vh !important;
}
.hcSelectedExtend {
margin-right: -2rem;
}
}
.hc-ga-icon {
width: 1rem;
height: 1rem;
display: inline-block;
}

.hc-ga-icon-ds {
background-color: #3793BB;
}

.hc-ga-icon-al {
background-color: #FFAC4B;
}

.hcLayoutCols122 {
display: flex;
flex-direction: row;
}

.hcLayoutCols122 > * {
flex: 2;
}

.hcLayoutCols122 > *:first-child {
flex: 1;
}

.hcLayoutCols112 {
display: flex;
flex-direction: row;
}

.hcLayoutCols112 > * {
flex: 1;
}

.hcLayoutCols112 > *:last-child {
flex: 3;
}

.hcVerticalLineSeperator > * {
border-right: 1px solid hsl(0deg, 0%, 85%);
}

.hcVerticalLineSeperator > *:last-child {
border-right: none;
}

.tempImg {
width: auto;
height: 65vh;
}

.hcQBHeight {
min-height: 45vh;
}

.hcClearBg {
background-image: none;
}

.hcBgWhite {
background-color: #ffffff;
}

.hcMinimanFullViewport {
min-width: 100vw;
min-height: 100vh;
display: flex;
flex-direction: column;
}

.hcFlex1 {
flex: 1;
}

@media (min-width: 1300px) {
#hcGaPanelRight {
max-width: 50vw;
}
}
.hcGaPadLeft {
margin-left: 0rem;
padding-left: 0rem;
}

.hcGaProces {
display: flex;
flex-direction: column;
}
.hcGaProces > * {
display: flex;
flex-direction: row;
padding: 0.5rem 0;
border-bottom: 1px solid #d7d7d7;
align-items: baseline;
}

.hcTabContent .hcListDisctinctLines > *:nth-child(odd) {
background-color: hsl(0deg, 0%, 90%);
}

.hcGaSce {
display: flex;
flex-direction: column;
}

.hcQBuildBlockBrowse .hc2columns {
flex-basis: auto;
}

.gaSplitCol {
flex-direction: column;
}

.dataTables_wrapper > * {
width: 100%;
font-size: 15px;
}
.dataTables_wrapper table {
margin-top: 1rem;
margin-bottom: 1rem;
width: 100%;
border-collapse: collapse;
}
.dataTables_wrapper table th {
text-align: left;
}
.dataTables_wrapper table td {
white-space: nowrap;
padding: 0.4rem;
}
.dataTables_wrapper table .odd {
background-color: rgba(0, 0, 0, 0.05);
}
.dataTables_wrapper .row:first-child {
display: flex;
justify-content: space-between;
flex-direction: row-reverse;
margin-bottom: 1rem;
}
.dataTables_wrapper .dataTables_filter label {
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: baseline;
}
.dataTables_wrapper .row:nth-child(2) {
display: flex;
justify-content: space-between;
align-items: baseline;
flex-wrap: wrap;
margin-bottom: 1rem;
}
.dataTables_wrapper .row:nth-child(2) > *:nth-child(1) {
order: 2;
width: 50%;
text-align: right;
}
.dataTables_wrapper .row:nth-child(2) > *:nth-child(1) select {
width: 70px;
}
.dataTables_wrapper .row:nth-child(2) > *:nth-child(2) {
order: 1;
width: 50%;
}
.dataTables_wrapper .row:nth-child(2) > *:nth-child(3) {
order: 3;
width: 100%;
}
.dataTables_wrapper .row:nth-child(2) .dataTables_length label {
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: baseline;
order: 3;
}
.dataTables_wrapper .row:nth-child(2) .dataTables_info {
order: 1;
width: 100%;
}
.dataTables_wrapper .row:nth-child(3) {
overflow-y: auto;
}
.dataTables_wrapper .dataTables_paginate {
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: baseline;
order: 2;
}
.dataTables_wrapper .dataTables_paginate a {
margin-right: 0.5rem;
cursor: pointer;
padding: 0.1rem 0.5rem;
}
.dataTables_wrapper .dataTables_paginate .current {
background-color: #efc501;
color: #000;
border-radius: 2px;
}

.hcCardGaDataset {
padding: 1.5rem;
background-color: #fff;
min-height: 150px;
border-radius: 4px;
box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.2);
}
.hcCardGaDataset h2 {
font-size: 1.2rem;
color: hsl(0deg, 0%, 10%);
}

.hcCardGaDataset--float {
transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.hcCardGaDataset--float:hover {
box-shadow: 5px 13px 10px rgba(0, 0, 0, 0.2);
transform: translate(0, -8px);
}

.hcGaLayoutSplit {
display: flex;
flex-direction: column;
}

.hcGaLayoutSplit:before {
content: "";
border: 1px solid hsl(0deg, 0%, 91%);
align-self: stretch;
}

.hcGaLayoutSplit > *:first-child {
order: -1;
}

@media (min-width: 1000px) {
.hcGaLayoutSplit {
flex-direction: row;
gap: 2rem;
}
.hcGaLayoutSplit > * {
flex: 1;
}
}
.hcGaIcon {
height: 0.8rem;
width: auto;
margin-right: 0.4rem;
}

.hcGaIcon--big {
height: 1.3rem;
}

.ga-searcChoiceLink small {
color: #3e3e3e;
}

.listNoListItem li {
list-style: none;
}

.ga3col {
display: flex;
flex-direction: column;
width: 100%;
gap: 30px;
}
.ga3col > * {
flex: 1;
}

@media (min-width: 900px) {
.ga3col {
flex-direction: row;
}
}
.gaListItemLong {
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
gap: 30px;
border-bottom: 1px solid hsl(0deg, 0%, 80%);
}
.gaListItemLong .gaListItemLong__img {
flex: 1;
}
.gaListItemLong .gaListItemLong__img img {
width: 100px;
height: auto;
}
.gaListItemLong .gaListItemLong__txt {
flex-grow: 1;
}
.gaListItemLong .gaListItemLong__link {
flex: 1;
}

@media (min-width: 900px) {
.gaListItemLong {
flex-direction: row;
}
}